<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-3">
        <a-collapse>
          <a-collapse-panel key="1" header="Filters">
            <a-date-picker
              class="mr-2 mt-2"
              :disabled-date="disabledStartDate"
              v-model:value="start_date"
              placeholder=" Tanggal Awal"
              format="DD MMMM YYYY"
            />

            <a-date-picker
              class="mr-2 mt-2"
              :disabled-date="disabledEndDate"
              v-model:value="end_date"
              placeholder=" Tanggal Akhir"
              format="DD MMMM YYYY"
            />
            <a-button
              class="mt-2"
              type="primary"
              title="cari"
              @click="search"
              :loading="isFetching"
            >
              <i class="fa fa-filter" aria-hidden="true" v-if="!isFetching"></i>
            </a-button>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart-data-kokoh :timestamp="timestamp" title="Data ID Toko" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-lg-6 col-md-12">
        <div class="card" style="height: 92%">
          <div class="card-body">
            <div class="card-body"><user-info /></div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12" v-if="(isGm||isAm||isSm||isSsm||isAdminBk)">
        <div class="card">
          <div class="card-body">
            <e-chart-data-ca :timestamp="timestamp" title="Data Total Transaksi Toko" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12" v-if="(isGm||isAm||isSm||isSsm||isAdminBk)">
        <div class="card">
          <div class="card-body">
          <e-chart-data-sellout :timestamp="timestamp" title="Data Total Sell Out Gudang" />
        </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart-top-region :timestamp="timestamp" :title="`Top Penjualan Regional`" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart-top-product :timestamp="timestamp" :title="`Top 5 Produk Penjualan Semen`" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart-top-area :timestamp="timestamp" :title="`Top 5 Area Penjualan Semen`" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart-top-distributor
              :timestamp="timestamp"
              :title="`Top 5 Distributor Penjualan Semen`"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart-top-toko :timestamp="timestamp" :title="`Top 5 Toko Transaksi Semen`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { defineAsyncComponent, onMounted, ref, watch, reactive, toRefs, computed } from 'vue'
import moment from 'moment'
import { useDisabledDate } from '@/composables/DisabledDate'
import useUserInfo from '@/composables/useUserInfo'

export default {
  components: {
    UserInfo: defineAsyncComponent({
      loader: () => import('@/components/widgets/UserInfo'),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartDataKokoh: defineAsyncComponent({
      loader: () => import('@/components/charts/EChartDataKokoh' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartDataCa: defineAsyncComponent({
      loader: () => import('@/components/charts/EChartDataCA' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartDataSellout: defineAsyncComponent({
      loader: () => import('@/components/charts/EChartDataSellOut' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartTopRegion: defineAsyncComponent({
      loader: () => import('@/components/charts/EChartTopRegion' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartTopProduct: defineAsyncComponent({
      loader: () => import('@/components/charts/EChartTopProduct' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartTopArea: defineAsyncComponent({
      loader: () => import('@/components/charts/EChartTopArea' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartTopDistributor: defineAsyncComponent({
      loader: () =>
        import('@/components/charts/EChartTopDistributor' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    EChartTopToko: defineAsyncComponent({
      loader: () => import('@/components/charts/EChartTopToko' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
      timeout: 3000,
    }),
  },
  setup(props, { emit }) {
    const MonthYear = ref(moment(new Date()).format('MMMM yyyy'))
    const timestamp = ref()
    const isFetching = ref(false)
    const { isAm,isSm,isSsm, isGm, isAdminBk } = useUserInfo()
    const {
      start_date,
      end_date,
      startDate,
      endDate,
      disabledStartDate,
      disabledEndDate,
    } = useDisabledDate()

    onMounted(() => {
      search()
    })

    if (!start_date.value) {
      start_date.value = moment().startOf('year')
    }

    if (!end_date.value) {
      end_date.value = moment().endOf('month')
    }

    const search = () => {
      isFetching.value = true
      timestamp.value = { startDate: startDate.value, endDate: endDate.value }
      setTimeout(() => {
        isFetching.value = false
      }, 500)
    }

    return {
      MonthYear,
      start_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      search,
      timestamp,
      isFetching,
      isAm,
      isSm,
      isSsm,
      isGm,
      isAdminBk,
      // user,
    }
  },
}
</script>

<style></style>
